import {
  AutoComplete,
  Button,
  Col,
  DatePicker,
  InputNumber,
  Row,
  Select,
  Tag
} from 'antd'
import { DialogKeys } from 'components/modals/types'
import ValidateTimeInput from 'components/time-input'
import VendorModal from 'components/vendor-modal'
import { OverlayActions } from 'data/actions'
import { TaskContext } from 'pages/app/receipt-review/task/context'
import { RecordActions } from 'pages/app/receipt-review/task/reducers/actions'
import React, { useContext, useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { QueryKeys } from 'services/api/query-key'
import { Noctua2ApprovalApi } from 'services/api/requests/notua-ii'
import { RetailersType } from 'services/api/requests/notua-ii/approval'

import { compareCaseInsensitive, isSubStringOf } from 'services/helpers/string'
import { formatDate, formatTime } from 'services/helpers/time'
import useDebounceEvent from 'services/hooks/use-debounce-event'

const TaskContent = () => {
  const dispatch = useDispatch()
  const { state: parentState, dispatchTask: parentDispatcher } = useContext(
    TaskContext
  )

  const handleDispatchTask = (action, value = null) =>
    parentDispatcher({ type: action, payload: value })

  const { record } = parentState

  const {
    total,
    vendorName,
    rejectionReason,
    status,
    id,
    notes,
    purchaseDate,
    purchaseTime
  } = record

  const [rejectionSearch, setRejectionSearch] = useState<string | null>(null)

  const [searchText, setSearchText] = useState<string>('')

  const [missingRejectionError, setMissingRejectionError] = useState<string>('')

  const queryClient = useQueryClient()
  const cachedSearchOptions: RetailersType[] =
    queryClient.getQueryData([QueryKeys.SearchOfflineRetailer]) || []

  // only enable if queryClient can't find option
  const { isFetching: isSearching, refetch: refetchSearchQuery } = useQuery(
    [QueryKeys.SearchOfflineRetailer],
    () => Noctua2ApprovalApi.searchRetailers(searchText),
    {
      enabled: false,
      onSuccess: searchResult => {
        const newSearchFound = searchResult.filter(
          newOption =>
            !cachedSearchOptions.find(prevOptions =>
              compareCaseInsensitive(prevOptions.name, newOption.name)
            )
        )

        if (newSearchFound.length) {
          queryClient.setQueryData(
            [QueryKeys.SearchOfflineRetailer],
            Array.from(new Set([...cachedSearchOptions, ...newSearchFound]))
          )
        }
      }
    }
  )

  const { mutateAsync: mutateRetailer } = useMutation(
    Noctua2ApprovalApi.addNewRetailer
  )

  const debouncedSearchVendor = useDebounceEvent(
    async () => await refetchSearchQuery(),
    [searchText]
  )

  useEffect(() => {
    const isExistInCachedOptions = cachedSearchOptions.find(option =>
      compareCaseInsensitive(option.name, searchText)
    )

    if (searchText.length > 1 && !isExistInCachedOptions) {
      debouncedSearchVendor()
    }
  }, [searchText])

  const handleAddNewVendorClick = () =>
    dispatch(
      OverlayActions.showDialog({
        key: DialogKeys.ADD_NEW_VENDOR,
        component: <VendorModal onOk={mutateRetailer} />
      })
    )

  // vendor change
  const handleSelectVendorName = (value, option) => {
    // update vendor item
    handleDispatchTask(RecordActions.EDIT_VENDOR_NAME, value)
    //  update searchText
    setSearchText(value)
  }

  // vendor search
  const handleSearchVendor = async value => {
    const specialCharacterReg = /[^A-Za-z0-9]/g
    const formattedSearchText = value.replace(specialCharacterReg, '_')

    setSearchText(formattedSearchText)
  }

  const formattedVendorOptions = cachedSearchOptions.map(option => ({
    value: option.name,
    label: option.name
  }))

  const renderItem = (
    label: string,
    item: any,
    handleOnChange: any = () => {}
  ) => {
    switch (label) {
      case 'Date':
        return (
          <Col span={12}>
            <Row>
              <span className="font-bold">{label}</span>
            </Row>
            <Row>
              <DatePicker
                className={`w-full ${!item ? 'border-red-700' : ''}`}
                allowClear={false}
                defaultValue={item}
                onChange={handleOnChange}
                format={'YYYY-MM-DD'}
              />
            </Row>
          </Col>
        )
      case 'Time':
        return (
          <Col span={12}>
            <ValidateTimeInput
              format={'HH:mm'}
              value={item}
              fieldName={'purchaseTime'}
              label={'Time'}
              handleOnValid={handleOnChange}
            />
          </Col>
        )
      case 'Total':
        return (
          <div className="mb-4">
            <Row>
              <span className="font-bold">{label}</span>
            </Row>
            <Row>
              <InputNumber
                className={`w-full ${
                  !item && item !== 0 ? 'border-red-700' : ''
                }`}
                value={item}
                onChange={handleOnChange}
                placeholder={'Receipt total...'}
              />
            </Row>
          </div>
        )
      case 'Vendor':
        return (
          <div className="mb-4">
            <Row justify="space-between">
              <span className="font-bold">{label}</span>
              <Button
                type="link"
                className="pb-0"
                onClick={handleAddNewVendorClick}
              >
                Add new
              </Button>
            </Row>
            <Row>
              <Select
                showSearch
                placeholder="Vendor name..."
                optionFilterProp="children"
                style={{ width: '100%' }}
                onChange={handleSelectVendorName}
                onSearch={handleSearchVendor}
                filterOption={(inputValue, option) =>
                  isSubStringOf(option!.value, inputValue)
                }
                loading={isSearching}
                defaultValue={item}
                value={item}
                searchValue={searchText}
                options={formattedVendorOptions}
              />
            </Row>
          </div>
        )
      case 'Rejection reason':
        return (
          <div className="mb-4">
            <Row>
              <span className="font-bold">{label}</span>
            </Row>
            <Row>
              <AutoComplete
                autoFocus
                className={`text-xs rounded w-full ${
                  missingRejectionError ? 'border border-red-400' : ''
                }`}
                onClick={event => event.stopPropagation()}
                defaultValue={item}
                value={rejectionSearch === null ? item : rejectionSearch}
                onSearch={value => setRejectionSearch(value)}
                onSelect={value => {
                  setMissingRejectionError(null)
                  setRejectionSearch(null)
                  handleDispatchTask(RecordActions.EDIT_REJECTION_REASON, value)
                }}
                onClear={() => {
                  setMissingRejectionError(null)
                  setRejectionSearch(null)
                  handleDispatchTask(RecordActions.EDIT_REJECTION_REASON, null)
                }}
                allowClear
                options={reviewReceiptRejectionReasons.map(i => ({ value: i }))}
                placeholder="Rejection reason..."
                filterOption={(inputValue, option) =>
                  isSubStringOf(option!.value, inputValue)
                }
              />
              <span className="text-red-400">{missingRejectionError}</span>
            </Row>
          </div>
        )
    }
  }

  return (
    <div className="p-6">
      {/* general receipt info */}
      <Row className="flex justify-between items-start mb-3">
        <Col span={12}>
          <Row className="mb-3">
            <Tag color="blue">
              <span className="font-bold text-blue-700">{status}</span>
            </Tag>
          </Row>
          <Row>
            <span className="mr-1">Receipt ID:</span>
            <Tag>{id}</Tag>
          </Row>
        </Col>

        <Col span={12}>
          <Row>
            <span className="font-bold">Notes:</span>
          </Row>
          <span>{notes}</span>
        </Col>
      </Row>

      {/* Editable fields */}
      {renderItem('Total', total, value =>
        handleDispatchTask(RecordActions.EDIT_TOTAL, value)
      )}
      <Row className="mb-4" gutter={12}>
        {renderItem('Date', formatDate(purchaseDate), (_, dateString) =>
          handleDispatchTask(RecordActions.EDIT_PURCHASE_DATE, dateString)
        )}
        {renderItem('Time', formatTime(purchaseTime), timeString =>
          handleDispatchTask(RecordActions.EDIT_PURCHASE_TIME, timeString)
        )}
      </Row>
      {renderItem('Vendor', vendorName)}
      {renderItem('Rejection reason', rejectionReason)}
    </div>
  )
}

const reviewReceiptRejectionReasons = [
  // 'RECEIPT_OLDER_THAN_30_DAYS',
  // 'RECEIPT_OLDER_THAN_14_DAYS',
  // 'GIFT_CARD_PURCHASES_NOT_ACCEPTED',
  // 'PETROL_ONLY_NOT_SUPPORTED',
  // 'A4_SIZE_RECEIPTS_NOT_ACCEPTED',
  // 'ACCOUNT_NOT_FOUND',
  'TOTAL_CANNOT_BE_ZERO',
  // 'SPLIT_RECEIPT',
  // 'TOO_MANY_RECEIPTS',
  'UNREADABLE_RECEIPT',
  'EFTPOS_RECEIPT_NOT_ACCEPTED',
  'BLURRY_RECEIPT',
  'RETAILER_NOT_SUPPORTED',
  'SUSPICIOUS_ACTIVITY',
  'DATE_NOT_INCLUDED',
  'RETAILER_UNKNOWN',
  'ITEMS_UNIDENTIFIED',
  'REPEAT_SUBMISSION',
  'DAILY_RETAILER_LIMIT_REACHED',
  'BAD_BACKGROUND',
  'SERVICE_RECEIPTS_NOT_ACCEPTED',
  'TOTAL_IS_MISSING',
  'SCREENSHOTS_NOT_ACCEPTED',
  'PAYMENT_RECEIPTS_NOT_ACCEPTED',
  'DUPLICATE_EMAIL_ORDER_STATUS',
  'INCORRECT_PAYID',
  'VERIFYING_RECEIPTS',
  'RECEIPT_OLDER_THAN_7_DAYS'
]

const CTAButtons = ({
  isApproving,
  isRejecting,
  onApproveClick,
  onRejectClick
}) => {
  return (
    <Row gutter={16}>
      <Col md={18}>
        <Button
          loading={isApproving}
          type="primary"
          htmlType="submit"
          onClick={async () => await onApproveClick()}
          block={true}
          size="large"
        >
          Approve
        </Button>
      </Col>
      <Col md={6}>
        <Button
          loading={isRejecting}
          type="ghost"
          danger
          htmlType="submit"
          onClick={async () => await onRejectClick()}
          block={true}
          size="large"
        >
          Reject
        </Button>
      </Col>
    </Row>
  )
}

export default TaskContent
